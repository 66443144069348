import React, { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './user-approval.module.css';

const ApprovalComponent = ({ userId, onClose, onUpdate }) => {
  const [approvalType, setApprovalType] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  const CONFIRMATION_PHRASE = 'DESEJO APROVAR ESSE USUÁRIO COM VOUCHER';

  const handleApprovalTypeChange = (type) => {
    setApprovalType(type);
    setConfirmationText('');
  };

  const handleConfirmationTextChange = (e) => {
    setConfirmationText(e.target.value.toUpperCase());
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const action = approvalType === 'level1' ? 'approve' : 'approve-voucher';
      const response = await axios.put(`${process.env.REACT_APP_DATABASE}/admin/users/${userId}/approval`, { action }, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      toast.success(response.data.message);
      onClose();
      onUpdate();
    } catch (error) {
      toast.error(error.response?.data?.error || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setApprovalType(null);
    setConfirmationText('');
    onClose();
  };

  const isConfirmationValid = confirmationText === CONFIRMATION_PHRASE;

  const renderConfirmationInput = () => (
    <div className={styles.confirmationSection}>
      <p className={styles.confirmationPhrase}>
        {CONFIRMATION_PHRASE.split('').map((char, index) => (
          <span
            key={index}
            className={confirmationText[index] === char ? styles.correct : ''}
          >
            {char}
          </span>
        ))}
      </p>
      <div className={styles.confirmationInputContainer}>
        <input
          ref={inputRef}
          type="text"
          value={confirmationText}
          onChange={handleConfirmationTextChange}
          className={styles.confirmationInput}
          placeholder="Digite a frase acima"
          onPaste={(e) => e.preventDefault()}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.approvalPopup}>
      <div className={styles.approvalContainer}>
        <button className={styles.closeButton} onClick={handleClose}>&times;</button>
        <h2 className={styles.title}>Aprovar Usuário</h2>
        <div className={styles.optionsContainer}>
          {['level1', 'level2'].map((level) => (
            <button
              key={level}
              className={`${styles.optionButton} ${approvalType === level ? styles.selected : ''}`}
              onClick={() => handleApprovalTypeChange(level)}
            >
              {level === 'level1' ? 'Sem Voucher (Nível 1)' : 'Com Voucher (Nível 2)'}
            </button>
          ))}
        </div>
        {approvalType === 'level2' && renderConfirmationInput()}
        <button
          className={styles.confirmButton}
          onClick={handleConfirm}
          disabled={(approvalType === 'level2' && !isConfirmationValid) || approvalType === null || isLoading}
        >
          {isLoading ? 'Processando...' : 'Confirmar Aprovação'}
        </button>
      </div>
    </div>
  )
};

export default ApprovalComponent;